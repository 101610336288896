<template>
  <div class="data-completion">
    <el-steps :active="stepsActive" align-center finish-status="success">
      <el-step :title="$t('inputInfo')"></el-step>
      <el-step :title="$t('uploadImage')"></el-step>
      <el-step :title="$t('accomplish')"></el-step>
    </el-steps>

    <el-form v-show="stepsActive==0" :model="formData" :rules="rules" ref="formData" class="have-steps completion" id="data-completion">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="$t('org')+$t('speciesName')" prop="name">
            <el-input size="medium" v-model="formData.name" :placeholder="$t('speciesName')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('homeCountry')+'/'+$t('region')" prop="countryRegion">
            <el-cascader size="medium" v-model="formData.countryRegion" :options="options" clearable :props="{ value: 'id', label: 'name' }"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('businessLicense')+$t('number')" prop="businessLicenseNo">
            <el-input size="medium" v-model="formData.businessLicenseNo" :placeholder="$t('businessLicense')+$t('number')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('linkPhone')" prop="linkPhone">
            <el-input size="medium" v-model="formData.linkPhone" :placeholder="$t('linkPhone')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('bankName')" prop="bankName">
            <el-input size="medium" v-model="formData.bankName" :placeholder="$t('bankName')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('bankAccount')" prop="bankAccount">
            <el-input size="medium" v-model="formData.bankAccount" :placeholder="$t('bankAccount')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('org')+$t('address')" prop="orgAddress">
            <el-input size="medium" v-model="formData.orgAddress" :placeholder="$t('org')+$t('address')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('legalPerson')+$t('lastName')" prop="legalpersonSurname">
            <el-input size="medium" v-model="formData.legalpersonSurname" :placeholder="$t('legalPerson')+$t('lastName')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('firstName')" prop="legalpersonName">
            <el-input size="medium" v-model="formData.legalpersonName" :placeholder="$t('firstName')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('legalPerson')+$t('idNumber')" prop="legalpersonCard">
            <el-input size="medium" v-model="formData.legalpersonCard" :placeholder="$t('legalPerson')+$t('idNumber')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('remark')">
            <el-input size="medium" v-model="formData.remark" :placeholder="$t('remark')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('apply')+$t('explain')">
            <el-input size="medium" v-model="formData.applyRemark" :placeholder="$t('apply')+$t('explain')"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-form v-show="stepsActive==1" :model="formData2" :rules="rules" ref="formData2" class="have-steps completion">
      <el-row :gutter="20">
        <el-col class="yige">
          <el-form-item style="line-height:20px;" :label="$t('businessLicense')+$t('photograph')" prop="businessLicensePic">
            <div v-if="formData2.businessLicensePic" class="show-upload-img">
              <el-image :src="formData2.businessLicensePic" style="width:400px;height:200px"></el-image>
            </div>
            <el-upload v-else class="upload-demo" name="attach" drag :multiple="false" :show-file-list="false" :action="updateUrl" list-type="picture" :on-success="uploadSuccsee1" :on-remove="removeImage1">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                {{$t('dragTheFileHere')}}，{{$t('or')}}<em>{{$t('clickUpload')}}</em>
                <div class="el-upload__tip" slot="tip">{{$t('onlyJPGPNGFilesFanBeUploadedAndNoMoreThan500KB')}}</div>
              </div>
            </el-upload>
            <el-input type="hidden" v-model="formData2.businessLicensePic"></el-input>
          </el-form-item>
        </el-col>

        <el-col class="yige">
          <el-form-item :label="$t('legalPerson')+$t('photograph')" prop="legalpersonImg" class="image-uploading">
            <div v-if="formData2.legalpersonImg" class="show-upload-img">
              <el-image :src="formData2.legalpersonImg" style="width:400px;height:200px"></el-image>
            </div>
            <el-upload v-else class="upload-demo" name="attach" drag :multiple="false" :show-file-list="false" :action="updateUrl" list-type="picture" :on-success="uploadSuccsee2" :on-remove="removeImage2">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                {{$t('dragTheFileHere')}}，{{$t('or')}}<em>{{$t('clickUpload')}}</em>
                <div class="el-upload__tip" slot="tip">{{$t('onlyJPGPNGFilesFanBeUploadedAndNoMoreThan500KB')}}</div>
              </div>
            </el-upload>
            <el-input type="hidden" v-model="formData2.legalpersonImg"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span v-loading="true" v-if="stepsActive==2" class="max-loading"></span>

    <div v-if="stepsActive>=3" class="accomplish">
      <el-progress type="circle" v-if="stepsActive==3" :stroke-width="10" :percentage="percentage" :width="200" status="success"></el-progress>
      <div v-if="stepsActive==3" style="text-align:center;color:#67C23A;font-size:40px">{{$t('submit')+$t('success')}}<br></div>
      <el-progress type="circle" v-if="stepsActive==4" :stroke-width="10" :percentage="percentage" :width="200" status="exception"></el-progress>
      <div v-if="stepsActive==4" style="text-align:center;color:#F56C6C;font-size:40px">{{$t('submit')+$t('failing')}}<br>{{failingMsg}}</div>
    </div>

    <div class="bottom-button">
      <el-button size="medium" v-if="(stepsActive>0&&stepsActive<2)" @click="--stepsActive">{{$t('prevStep')}}</el-button>
      <el-button size="medium" v-if="stepsActive===4" @click="stepsActive=1">{{$t('prevStep')}}</el-button>
      <el-button size="medium" v-if="stepsActive==0" @click="next('formData')">{{$t('nextStep')}}</el-button>
      <el-button size="medium" v-if="stepsActive==1" type="primary" @click="onSubmit('formData2')">{{$t('submit')+$t('apply')}}</el-button>
    </div>
  </div>
</template>

<script>
import uploading from '../api/uploading'
import org from '../api/org'
import accountApply from '../api/accountApply'
export default {
  props: ['orgInfo', 'callback'],
  data () {
    return {
      formData: {},
      formData2: {},
      // options: [],
      isUpdateSuccess1: false,
      isUpdateSuccess2: false,
      stepsActive: 0,
      percentage: 0,
      failingMsg: "",
    }
  },
  computed: {
    updateUrl () { return uploading.uploadImg() },
    options: { get () { return this.$store.state.globalParameter.countryTree; }, set () { } },
    rules () {
      // let upload = (rule, value, callback) => {
      //   debugger
      //   if (value === '' || !value) {
      //     callback(new Error(this.$t('please') + this.$t('upload') + this.$t('image')));
      //   } else {
      //     callback();
      //   }
      // };
      return {
        name: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        countryRegion: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        businessLicenseNo: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        linkPhone: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        bankName: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        bankAccount: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        orgAddress: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        legalpersonSurname: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        legalpersonName: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        legalpersonCard: [{ required: true, message: this.$t('notNull'), trigger: 'blur' }],
        legalpersonImg: [{ required: true, message: this.$t('please') + this.$t('upload') + this.$t('image'), trigger: 'blur' }],
        businessLicensePic: [{ required: true, message: this.$t('please') + this.$t('upload') + this.$t('image'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    next (refName) {
      this.$refs[refName].validate(valid => {
        if (valid) ++this.stepsActive;
        return false;
      })
    },

    /**上传营业执照 */
    uploadSuccsee1 (response) {
      this.$set(this.formData2, 'businessLicensePic', response.data)
      this.isUpdateSuccess1 = true;
    },
    /**移除营业执照 */
    removeImage1 () {
      this.isUpdateSuccess1 = false;
    },
    /**上传法人照片 */
    uploadSuccsee2 (response) {
      this.$set(this.formData2, 'legalpersonImg', response.data)
      this.isUpdateSuccess2 = true;
    },
    /**移除法人照片 */
    removeImage2 () {
      this.isUpdateSuccess2 = false;
    },
    /**提交资料 */
    onSubmit (formRef) {
      let _this = this;
      this.$refs[formRef].validate(valid => {
        if (valid) {
          _this.stepsActive = 2;
          _this.formData.country = _this.formData.countryRegion[0];
          _this.formData.region = _this.formData.countryRegion[1];

          let data = { ..._this.formData, ..._this.formData2 }
          org.addOrgInfo({
            param: data,
            success: res => {
              if (res.code == 200) { _this.stepsActive = 3; }
              else {
                _this.failingMsg = _this.$t(res.msg);
                _this.stepsActive = 4;
              }

              _this.percentage = 100;
            }
          })
        }
        else {
          return false;
        }
      })
    },
    /**审核失败时获取上次提交的资料 */
    getSubmittedMaterial () {
      let _this = this;
      org.getOrgInfoPage({
        success: res => {
          if (res.code == 200) {
            _this.formData = res.data.records[0];
            _this.formData.countryRegion = [res.data.records[0].country, res.data.records[0].region];
            _this.formData2 = {
              businessLicensePic: res.data.records[0].businessLicensePic,
              legalpersonImg: res.data.records[0].legalpersonImg
            }
            _this.getRemark();
          }
        }
      })
    },
    /**获取审核失败时填写的备注 */
    getRemark () {
      let _this = this;
      accountApply.getVerifyDefeatedRemark({
        success: res => {
          _this.callback(res.data)
        }
      })
    },
  },
  mounted () {
    this.getSubmittedMaterial();
  }
}
</script>
<style lang="less">
.data-completion {
  .yige {
    width: 420px;
    float: left;
  }
  .upload-demo {
    width: 400px;
    height: 200px;
    .el-icon-upload {
      margin: 16px 0;
    }
    .el-upload {
      width: 100%;
      height: 100%;
      .el-upload-dragger {
        width: 100%;
        height: 100%;
      }
    }
    .el-upload__tip {
      line-height: 0;
    }
    .show-upload-img {
      width: 100%;
    }
  }
  .el-form-item__content {
    line-height: 20px;
  }
}
</style>