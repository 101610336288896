<template>
  <div class='layout' @mousemove="lastOperatingTime = new Date().getTime()">
    <!-- 顶部菜单 -->
    <el-menu class="el-menu-horizontal-demo" mode="horizontal" background-color="#296bef" text-color="#fff" active-text-color="#296bef">
      <el-menu-item :class="leftMenu.isCollapse?'left-fold':'left-unfold'">
        <el-image :src="require('../assets/image/Logo/logo.png')" fit="LOGO"> </el-image>
        <div v-show="!leftMenu.isCollapse" class="logo-text">BNIF AFUWA</div>
      </el-menu-item>

      <el-menu-item @click="leftMenu.isCollapse=!leftMenu.isCollapse">
        <el-tooltip class="item" effect="dark" :content="$t('foldMenu')" placement="right">
          <i :class="`el-icon-s-${leftMenu.isCollapse?'unfold':'fold'}`"></i>
        </el-tooltip>
      </el-menu-item>

      <el-menu-item @click="refresh">
        <el-tooltip class="item" effect="dark" :content="$t('refresh')" placement="right">
          <i :class="`el-icon-refresh`"></i>
        </el-tooltip>
      </el-menu-item>

      <div class="keep-right">
        <div class="qr-show" @mouseenter="qrcodeShow=true" @mouseleave="qrcodeShow=false">
          <div class="APP">{{$t('Download app')}}</div>
          <div v-if="qrcodeShow">
            <div class="app-down-qr" v-if="qrcodeParam">
              <qr-code :param="qrcodeParam"></qr-code>
            </div>
          </div>
        </div>
        <el-dropdown @command="handleCommand">
          <div class="el-dropdown-link">
            <!-- <el-image :src="require('../assets/image/login/login.jpg')" round /> -->
            {{info?((info.name||'BNIF AFUWA')+" "+(info.surname||'')):$t('personalCenter')}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="accountInfo">{{$t('personalData')}}</el-dropdown-item>
            <el-dropdown-item command="passwordManage">{{$t('passwordManagement')}}</el-dropdown-item>
            <el-dropdown-item command="logOut">{{$t('logOut')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <lang />
    </el-menu>
    <!-- 左侧菜单 -->
    <el-menu :default-active="leftMenu.activeIndex" class="el-menu-vertical-demo" background-color="#222d32" text-color="#b8c7ce" active-text-color="#fff" :collapse="leftMenu.isCollapse" :unique-opened="true">
      <el-menu-item index="home" @click="selectedMenu('home')">
        <i class="iconfont iconiconsy_active"></i>
        <span slot="title">{{$t('home')}}</span>
      </el-menu-item>
      <el-menu-item index="businessHandling" @click="selectedMenu('businessHandling')">
        <i class="iconfont icon39"></i>
        <span slot="title">{{$t('businessHandling')}}</span>
      </el-menu-item>
      <el-submenu v-for="(t,i) in leftMenu.menu" :index="(i+2).toString()" :key="i">
        <template slot="title">
          <i :class="t.icon"></i>
          <span slot="title">{{$t(t.name)}}</span>
        </template>
        <el-menu-item v-for="(t2,i2) in t.children" :index="t2.name" :key="i+'-'+i2" @click="selectedMenu(t2.name)">
          <i :class="t2.icon"></i>
          {{$t(t2.name)}}
        </el-menu-item>
      </el-submenu>

      <!-- <el-menu-item index="salesNetwork" @click="selectedMenu('salesNetwork')">
        <i class="iconfont iconyingyewangdian"></i>
        <span slot="title">{{$t('sales network')}}</span>
      </el-menu-item> -->

      <!-- <span class="version-number">{{versionNumber}}</span> -->
    </el-menu>
    <!-- 右侧内容 -->
    <div class="right" :style="{left:leftMenu.isCollapse?'56px':'221px'}">
      <!-- type="card" -->
      <div class="rgiht-menu">
        <div class="move-right">
          <div class="controlled">
            <i class="iconfont iconjiantouzuo"></i>
          </div>
        </div>
        <el-tabs v-model="tabs.active" @tab-click="handleClick" @tab-remove="removeTab" style="flex:1">
          <el-tab-pane v-for="(t,i) in tabs.list" :key="i" :name="t.name" :closable="t.closable" :router="t" style="padding:0 10px">
            <span slot="label"><i class="iconfont iconyuandian"></i> {{$t(t.text)}} </span>
          </el-tab-pane>
        </el-tabs>
        <div class="move-left">
          <div class="controlled">
            <i class="iconfont iconjiantouyou"></i>
            <el-dropdown @command="batchRemoveTab" style="margin-left:10px">
              <span class="el-dropdown-link"> <i class="el-icon-arrow-down el-icon--right"></i> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="all">{{$t('all')}}{{$t('close')}}</el-dropdown-item>
                <el-dropdown-item command="right">{{$t('towardsRight')}}{{$t('close')}}</el-dropdown-item>
                <el-dropdown-item command="left">{{$t('towardsLeft')}}{{$t('close')}}</el-dropdown-item>
                <el-dropdown-item command="other">{{$t('close')}}{{$t('other')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="contetn-container">
        <div class="global-content">
          <keep-alive :include="keepAlive.toString()">
            <router-view v-if="test" />
          </keep-alive>
        </div>
      </div>
    </div>
    <el-drawer :modal="false" :visible.sync="showInformationPerfect" size="100%" :destroy-on-close="true" :wrapperClosable="false" :style="{left:leftMenu.isCollapse?'56px':'221px'}" :before-close="hiddenInformationPerfect">
      <template slot="title">
        <div>
          {{$t('pleaseCompleteTheInformationForFirstLogin')}}
          <div class="material-perfection-error_info">
            {{materialPerfection.errorInfo||$t('pleaseCompleteYourInformationAfterTheApprovalOfTheDisplayFunction')}}
          </div>
        </div>
      </template>
      <data-completion :orgInfo="info" :callback="setMaterialPerfection" />
    </el-drawer>
    <!-- <el-progress :percentage="this.percentage" :stroke-width="3"></el-progress> -->
  </div>
</template>
<script>
import lang from "@/components/language";
import dataCompletion from "@/components/dataCompletion";
import qrCode from '@/components/QRcode.vue'

import login from '@/api/login';
import storage from '@/assets/uilt/storage';
import { setWaterMark } from '@/assets/uilt/watermark'
export default {
  components: { lang, dataCompletion, qrCode },
  data () {
    return {
      keepAlive: ['home'],
      test: true,
      info: {},
      leftMenu: {
        activeIndex: "home",
        isCollapse: false,
        menu: new Array()
      },
      tabs: {
        active: "home",
        list: [{ name: "home", text: "home" }]
      },
      materialPerfection: { title: this.$t('pleaseCompleteTheInformationForFirstLogin'), errorInfo: "" },

      percentage: 0,

      qrcodeShow: false,
      qrcodeParam: '',

      /**最后操作时间，用于判断是否需要自动退出登录 */
      lastOperatingTime: new Date().getTime(),
      tiemoutIndex: 0,
      timeOut: 1000 * 60 * 30
    };
  },
  computed: {
    /**是否显示 */
    showInformationPerfect () { return this.$store.state.system.showInformationPerfect; },
    versionNumber () { return this.$store.state.system.versionNumber; },
  },
  watch: {
    "leftMenu.activeIndex" (newVal) { this.tabs.active = newVal; },
    "tabs.active" (newVal) { this.leftMenu.activeIndex = newVal; },
    "info" () {
      this.$nextTick(function () {
        setWaterMark(this.$manba(new Date()).toISOString() + ' ' + (this.info.name + ' ' + this.info.surname), this.versionNumber);
      })
    },

    // "lastOperatingTime" (newValue) {
    //   //第一步：清除之前创建的定时器
    //   clearTimeout(this.tiemoutIndex);
    //   //第二步，新建定时器，如果在某个时间段内没有操作则退出系统
    //   this.tiemoutIndex = setTimeout(() => {
    //     if (new Date().getTime() - newValue > this.timeOut) {
    //       this.handleCommand('logOut');
    //       return;
    //     }
    //   }, 1000 * 60 * 1);
    // }
  },
  methods: {
    /** 添加tab*/
    selectedMenu (name) {
      let old = JSON.stringify(this.leftMenu.activeIndex);
      if (this.leftMenu.activeIndex == name)
        return;
      this.leftMenu.activeIndex = name;

      if (this.tabs.list.find(function (o) { return o.name == name })) {
        this.$router.push({ name: name });
        return;
      }

      if (this.keepAlive.length > 10) {
        this.leftMenu.activeIndex = JSON.parse(old);
        this.$message.warning(this.$t('maximumTenTabs'))
        return;
      }

      if (this.$route.name != name) this.$router.push({ name: name });

      this.tabs.list.push({ name: name, text: name, closable: true });
      if (!this.keepAlive.find(function (o) { return o == name }))
        this.keepAlive.push(name);
    },
    /**移除选择中tab,并路由到下一个页面 */
    removeTab (tabName) {
      let tabs = this.tabs.list;
      let _this = this;
      tabs.forEach(function (t, i) {
        if (t.name == tabName) {
          let nextTab = tabs[i + 1] || tabs[i - 1];
          if (nextTab) {
            _this.tabs.active = nextTab.name;
            if (_this.$route.name !== nextTab.name)
              _this.$router.push({ name: nextTab.name });
          }
        }
      });
      let index = this.keepAlive.indexOf(tabName);
      this.keepAlive.splice(index, 1);

      _this.tabs.list = tabs.filter(function (tab) {
        return tab.name !== tabName
      });
    },
    /**批量关闭tab */
    batchRemoveTab (scope) {
      let arr = this.tabs.list;
      if (scope == "all") {
        if (this.$route.name != arr[0].name) {
          this.tabs.active = arr[0].name;
          this.$router.push({ name: arr[0].name });
        }
        this.tabs.list = [arr[0]];
        this.keepAlive = [arr[0].name];
      } else if (scope == "right") {
        let retain = [];
        let ns = [];
        for (let i = 0; i < arr.length; i++) {
          retain.push(arr[i])
          ns.push(retain[i].name)
          if (arr[i].name == this.tabs.active) {
            this.tabs.list = retain;
            this.keepAlive = ns;
            break;
          }
        }
      } else if (scope == "left") {
        let isRetain = false;
        let retain = [arr[0]];
        let ns = [arr[0].name];
        for (var i = 0; i < arr.length; i++) {
          if (!isRetain && arr[i].name == this.tabs.active) isRetain = true;
          if (isRetain) {
            retain.push(arr[i])
            ns.push(arr[i].name)
          }
        }
        this.tabs.list = retain;
        this.keepAlive = ns;
      } else if (scope == "other") {
        let _this = this;
        let retain = [];
        let ns = [];
        if (_this.tabs.active != arr[0].name) {
          let th = arr.find(function (o) {
            return o.name == _this.tabs.active
          });
          retain = [arr[0], th];
          ns = [arr[0].name, th.name]
        } else {
          retain = [arr[0]];
          ns = [arr[0].name];
        }
        this.tabs.list = retain;
        this.keepAlive = ns;
      }
    },
    /**tab点击回调 */
    handleClick (tab) {
      if (this.$route.name == tab.name)
        return;
      this.$router.push({ name: tab.name })
    },
    /**重置内嵌页面 */
    refresh () {
      let _this = this;
      _this.test = false;
      let index = _this.keepAlive.indexOf(_this.tabs.active);
      _this.keepAlive.splice(index, 1);

      _this.$nextTick(() => {
        _this.test = true;
        _this.keepAlive.push(_this.tabs.active)
      })
    },
    /**获取机构登录信息 */
    getInfo () {
      let _this = this;
      // console.log('-----layout------', new Date().getTime())
      login.getInfo({
        success: res => {
          //获取触发地区全局变量接口
          _this.$store.dispatch('globalParameter/getCountry');
          if (res.code != 200) return;
          _this.info = res.data;

          let parent = res.data.menu.filter(function (o) { return o.pid == 0 });
          parent.forEach(function (t) { t.children = res.data.menu.filter(function (o) { return o.pid == t.id }) })
          _this.leftMenu.menu = parent;

          //设置登录信息全局变量
          _this.$store.dispatch('globalParameter/setLoginInfo', JSON.parse(JSON.stringify(res.data)))

          _this.$router.push({ name: 'home' });
        }
      })
    },
    hiddenInformationPerfect () { },
    /**机构退出登录 */
    logout (fun) {
      let _this = this;
      login[fun]({
        success: res => {
          // _this.$message({
          //   type: res.code == 200 ? 'success' : 'warning',
          //   message: res.code == 200 ? _this.$t('operateSuccessfully') : (_this.$t(res.msg)),
          //   duration: 2000,
          // });



          if (res.code == 200) setTimeout(() => {
            let rouet = storage.local.get("loginName");
            _this.$router.replace(rouet || { name: 'merchantLogin' });
          }, 500);
        }
      })
    },
    /**设置标题 */
    setMaterialPerfection (remark) {
      this.materialPerfection.errorInfo = remark;
    },
    /**查询客户登录信息 */
    getCustomerInfo () {
      let _this = this;
      login.getCustomerInfo({
        success: res => {

          //获取触发地区全局变量接口
          _this.$store.dispatch('globalParameter/getCountry');

          if (res.code != 200) return;
          _this.info = res.data;

          let parent = res.data.menu.filter(function (o) { return o.pid == 0 });
          parent.forEach(function (t) {
            t.children = res.data.menu.filter(function (o) { return o.pid == t.id })
          })

          _this.leftMenu.menu = parent;

          res.data.type = 'customerLogin';
          //设置登录信息全局变量
          _this.$store.dispatch('globalParameter/setLoginInfo', JSON.parse(JSON.stringify(res.data)))

          _this.$router.push({ name: 'home' });
        }
      })
    },
    /**事件 */
    handleCommand (command) {
      if (command == "logOut") {
        let loginName = storage.local.get('loginName');
        if (!loginName)
          this.$router.push({ name: 'merchantLogin' })
        else if (loginName.name === 'merchantLogin')
          this.logout('orgLogout');
        else if (loginName.name === 'customerLogin')
          this.logout('customerLogout');
      }
      //跳转到个人信息页面
      else if (command == "accountInfo") {
        this.$router.push({ name: command })
      } else if (command == "passwordManage") {
        this.$router.push({ name: command })
      }
    },
  },
  created () {
    //刷新页面时定位到home页面
    if (this.$route.name != "layout")
      this.$router.push({ name: "layout" })
  },
  mounted () {
    let loginName = storage.local.get('loginName');
    if (!loginName) this.$router.push({ name: 'merchantLogin' })
    else if (loginName.name === 'merchantLogin') this.getInfo();
    else if (loginName.name === 'customerLogin') this.getCustomerInfo();

    //显示APP下载二维码
    if (loginName.name == 'merchantLogin')//机构
      this.qrcodeParam = location.origin + '/sys_cpanel/niorg.apk'
    else if (loginName.name == 'customerLogin')//个人
      this.qrcodeParam = location.origin + '/sys_cpanel/nicus.apk'

    // let _this = this;
    // let interval = setInterval(function () {
    //   _this.$set(_this, 'percentage', _this.percentage + 5);
    //   if (_this.percentage >= 100)
    //     clearInterval(interval)
    // }, 30);

    /**使用 vuex  */
    // this.$store.dispatch("system/test", { a: 1, b: 2, c: 3 });
    // console.log(this.$store.state.system.test2);
  },
}
</script>
<style lang='less' scoped>
.layout {
  .el-menu-horizontal-demo {
    border: none;
    height: 50px;

    .el-menu-item {
      height: 50px !important;
      line-height: 48px !important;
      padding: 0 10px;
    }

    .logo-text {
      font-family: "Helvetica Neue", Helvetica, "PingFang SC",
        "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
    }

    .el-image {
      width: 40px;
      height: 32px;
    }

    .left-unfold {
      padding: 0 20px;
      width: 221px;
      transition: all 300ms;
    }

    .left-fold {
      width: 64px;
      padding: 0 13px;
      transition: all 300ms;
    }

    .el-icon-s-fold,
    .el-icon-s-unfold,
    .el-icon-refresh {
      font-size: 18px;
    }

    .el-menu-item * {
      color: @white;
    }

    .keep-right {
      float: right;
      margin-right: 165px;

      .el-dropdown {
        .el-dropdown-link {
          cursor: pointer;
          height: 50px;
          line-height: 50px;
          color: #fff;

          .el-image {
            vertical-align: middle;
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .el-menu-vertical-demo,
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    padding-top: 20px;
    overflow: auto;
    display: inline-block;
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
  }

  .el-menu-vertical-demo {
    width: 64px;

    .el-submenu .el-menu-item.is-active {
      background: @primary !important;
      color: @white !important;
    }

    .el-submenu .el-menu-item {
      font-size: 13px;
      height: 40px;
      line-height: 40px;
      background: #0000004d !important;
    }

    .el-menu-item span,
    .el-submenu__title span,
    .el-submenu__title i,
    .el-menu-item i {
      margin-right: 10px;
      font-size: 13px;
      font-weight: bold;
      vertical-align: unset;
    }
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 221px;
  }

  .el-menu-vertical-demo::-webkit-scrollbar {
    display: none;
  }

  .right {
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 221px;
    transition: left 300ms;

    .rgiht-menu {
      display: flex;

      .move-left,
      .move-right {
        background: @french_grey;

        .controlled {
          padding: 0 15px;
          line-height: 40px;
          background: @white;
          height: 38px;
          border-bottom: 2px solid #e4e7ed;
          display: inline-block;
          cursor: pointer;
          color: #808080;
        }
      }

      .move-left {
        .controlled {
          border-left: 1px solid @french_grey;
        }
      }

      .move-right {
        .controlled {
          border-right: 1px solid @french_grey;
        }
      }
    }

    .contetn-container {
      overflow: auto;
      background: @french_grey;
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      bottom: 0;
      font-size: 14px;

      .global-content {
        position: relative;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        margin: 15px;
      }
    }
  }

  .el-drawer__wrapper {
    z-index: 2000 !important;
    top: 51px;
    bottom: 0;
    right: 0;
    padding: 0;
    transition: left 300ms;
  }

  .version-number {
    position: fixed;
    bottom: 20px;
    left: 20px;
    color: @info;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
  }
}
</style>
<style lang="less">
.layout {
  .el-menu-vertical-demo {
    .el-submenu .el-submenu__title {
      height: 50px;
      line-height: 50px;

      .el-submenu__icon-arrow {
        color: @white;
        font-size: 14px;
      }
    }

    .el-submenu .el-submenu__title::after {
      content: "";
      position: absolute;
      left: 0;
      width: 3px;
      top: 0;
      bottom: 100%;
      background: @primary !important;
      transition: bottom 300ms;
    }

    .el-submenu:hover .el-submenu__title::after {
      bottom: 0;
    }

    .el-submenu .el-menu--inline .el-menu-item:hover {
      background: @primary;
    }
  }

  .el-tabs {
    background: @french_grey;

    .el-tabs__header {
      background: @white;
    }
  }

  .el-tabs > .el-tabs__header .el-tabs__active-bar {
    background: transparent;
  }

  .el-tabs > .el-tabs__header .el-tabs__item {
    padding: 0 10px;
    font-size: 12px;
    color: #768595;
    border-right: 1px solid @french_grey;

    i {
      font-size: 12px;
      margin-right: 5px;
      color: @french_grey;
    }
  }

  .el-tabs > .el-tabs__header .el-tabs__item.is-active {
    color: @primary;

    i {
      color: @primary;
    }
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: @french_grey;
  }

  .el-menu-horizontal-demo .el-submenu__title {
    height: 50px;
    line-height: 50px;

    .el-submenu__icon-arrow {
      position: unset;
      top: unset;
      right: unset;
      margin-top: unset;
      margin-left: 10px;
      font-size: 14px;
      color: @white;
    }
  }
  .material-perfection-error_info {
    color: @danger;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }
  .qr-show {
    position: fixed;
    z-index: 99;
    // right: 18.75rem;
    left: 22.75rem;
    top: 14px;
    width: 170px;
    color: white;
    cursor: default;
    .APP:nth-child(1) {
      text-align: left;
      margin-bottom: 10px;
    }
    .app-down-qr {
      padding: 20px;
      background: @white;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0 0 5px -2px @primary;
    }
  }
}
</style>