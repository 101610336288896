import ajax from '../assets/uilt/ajax';

let accountApply = {
  /**分页获取账户申请列表 */
  getPage: req => { return ajax.get({ url: "/back/accApply/get-page", param: req.param, success: req.success }) },
  /**根据ID查询账户申请 */
  getById: req => { return ajax.get({ url: "/back/accApply/get-by-id", param: req.param, success: req.success }) },
  /**根据ID更新审核状态 */
  updateStatus: req => { return ajax.post({ url: "/back/accApply/update-status", param: req.param, success: req.success }) },
  /**查开户审核失败的备注 */
  getVerifyDefeatedRemark: req => { return ajax.get({ url: "/back/accApply/get-verify-defeated-remark", param: req.param, success: req.success }) },

}

export default accountApply;